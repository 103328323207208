import Layout from "../components/Layout";
import NextLink from "next/link";
import { VStack, Heading, Center, Link, Avatar } from "@chakra-ui/react";
const Home = () => {
	return (
		<Layout pageTitle="Takayuki Kusano （草野貴之）">
			<VStack spacing="36px">
				<Heading>Takayuki Kusano</Heading>
				<Center>
					<NextLink href="/profile">
						<Link>
							<Avatar name="Takayuki Kusano" src="/img/tkusano-face-avatar.jpg" />
						</Link>
					</NextLink>
				</Center>
			</VStack>
		</Layout>
	);
};
export default Home;
